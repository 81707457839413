<template>
    <div>
        <vue-countdown :time="getDates[0]" v-slot="{ days, hours, minutes, seconds }" @end="getNext(index)">
            <div class="count-down">
                <div class="date">
                    <div class="date__content">
                        <h5 class="date__content__title">{{ days }}</h5>
                    </div>
                    <span class="date__subtitle">days</span>
                </div>
                <div class="date">
                    <div class="date__content">
                        <h5 class="date__content__title">{{ hours }}</h5>
                    </div>
                    <span class="date__subtitle">hours</span>
                </div>
                <div class="date">
                    <div class="date__content">
                        <h5 class="date__content__title">{{ minutes }}</h5>
                    </div>
                    <span class="date__subtitle">mins</span>
                </div>
                <div class="date">
                    <div class="date__content">
                        <h5 class="date__content__title">{{ seconds }}</h5>
                    </div>
                    <span class="date__subtitle">secs</span>
                </div>
            </div>
            <!--Time Remaining：{{ days }} days, {{ hours }} hours, {{ minutes }} minutes, {{ seconds }} seconds.-->
        </vue-countdown>
    </div>
</template>
<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {
    name: 'DateTimer',
    components: { VueCountdown },
    props: {
        claimingPeriods: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            index: 0,
            dates: [],
            timerTime: 0
        }
    },
    computed: {
        getDates() {
            return this.claimingPeriods
        }
    },
    watch: {},
    mounted() {},
    methods: {
        getNext() {
            this.getDates.splice(this.index, 1)
            if(this.getDates.length) {
                this.$emit('newreq')    
            } 
        },
    }
}
</script>
<style lang="scss">
.date{ 
    margin: 0px 10px;
    &__content {
        border: 1px solid rgb(255, 255, 255);
        width: fit-content;
        padding: 5px 15px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 4px;
            left: 43%;
            margin-top: -5px;
            border: 5px solid rgb(255, 255, 255);
            border-right-color: transparent;
            border-left-color: transparent;
            border-bottom-color: transparent;
        }
        &__title {
            font-size: 32px;
            font-weight: 550;
        }
    }
    &__subtitle {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
    }
    
}
.count-down {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>