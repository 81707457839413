<template>
  <popup v-model="popup" @input="close">
    <div class="transaction-confirm-popup">
      <h3 class="transaction-confirm-popup__title">
        No $MF tokens available for your wallet address.
      </h3>
      <!--<div class="transaction-confirm-popup__text">
        Check that you are connected to the Metamask. If you are already logged in and have <br>participated in investment rounds, then wait for the next claiming at the specified time
      </div>-->
    </div>
  </popup>
</template>

<script>
export default {
  name: "ClaimPopup",
  props: {
    hashes: {
      type: Array,
      default: () => []
    },
    sum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      popup: false,
    }
  },
  methods: {
    open(){
      this.popup = true
    },
    close() {
      this.$emit('close')
      this.popup = false
    }
  }
}
</script>

<style lang="scss" scoped>
.transaction-confirm-popup {
  word-break: break-word;
  text-transform: uppercase;
  &__title {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

  &__text {
    margin-top: 20px;
    text-align: center;
  }
  &__track {
    margin: 10px 0;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }
  &__sum {
    margin-top: 20px;
    text-align: center;
    text-align: center;
    display: block;
    &__text {
      font-weight: 600;
    }
  }
}
.declined { 
  color: red;
}
.success {
  color: rgb(26, 82, 238);
  text-decoration: underline;
}
.track {
  
  &__li {
    list-style: decimal;
    margin: 5px 20px;
    &.err {
      color: red;
    }
    &.transfered {
      color: rgb(26, 82, 238);
    }
  }
}
</style>
