// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".clamable {\n  display: block;\n  min-width: 300px;\n  margin: 20px 10px 0px 10px;\n  justify-content: center;\n  align-items: center;\n}\n.clamable__title {\n  text-align: center;\n}\n.clamable__title span {\n  text-transform: uppercase;\n  font-size: 24px;\n  font-weight: bold;\n}\n.clamable__count {\n  text-align: center;\n}\n.clamable__count span {\n  text-transform: uppercase;\n  font-size: 36px;\n  font-weight: bold;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
