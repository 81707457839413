var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "claimer-btn",
      attrs: { type: "button" },
      on: { click: _vm.click },
    },
    [
      _c("span", { staticClass: "first-line" }),
      _c("span", { staticClass: "second-line" }),
      _c("span", { staticClass: "third-line" }),
      _c("span", { staticClass: "fourth-line" }),
      _c("span", { staticClass: "claimer-btn__content" }, [
        _c("span", { staticClass: "claimer-btn__content__title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }