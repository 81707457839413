var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "claiming" },
    [
      _vm.isLoading ? _c("Preloader") : _vm._e(),
      !_vm.isLoading
        ? _c("div", { staticClass: "claiming__content" }, [
            _vm._m(0),
            _c("div", { staticClass: "claimer" }, [
              !_vm.account
                ? _c("div", { staticClass: "claimer__loggedin" }, [_vm._m(1)])
                : _vm._e(),
              _vm.account
                ? _c(
                    "div",
                    { staticClass: "claimer__staking" },
                    [
                      _c("StakeBtn", {
                        staticClass: "claimer__staking__btn",
                        on: { click: _vm.goStacking },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.account
                ? _c("div", { staticClass: "claimer__content" }, [
                    _vm._m(2),
                    _c(
                      "div",
                      { staticClass: "claimer__content__date" },
                      [
                        _c("DateTimer", {
                          attrs: { claimingPeriods: _vm.claimingPeriods },
                          on: { newreq: _vm.getResponse },
                        }),
                      ],
                      1
                    ),
                    _vm._m(3),
                    _c(
                      "div",
                      { staticClass: "claimer__content__counter" },
                      [
                        _c("Field", {
                          attrs: { title: "all your mf", count: _vm.getAllMF },
                        }),
                        _c("Field", {
                          attrs: {
                            title: "mf claimed",
                            count: _vm.getMFclaimed,
                          },
                        }),
                        _c("Field", {
                          attrs: {
                            title: "mf remains",
                            count: _vm.getMFAvailable,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "claimer__content__expire" },
                      [
                        _c("ClaimingDate", {
                          attrs: {
                            startDate: _vm.startDate,
                            endDate: _vm.endDate,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "claimer__content__clamable" },
                      [
                        _c("Clamable", {
                          attrs: {
                            title: "claimable now",
                            count: _vm.getMFreleasablenow,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "claimer__content__button" },
                      [
                        _c("ClaimBtn", {
                          staticClass: "claimer__content__button__btn",
                          attrs: { title: "claim your mf", type: "play" },
                          on: { click: _vm.claimableNow },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.refreshPage
                ? _c("div", { staticClass: "claimer__claimed" }, [
                    _c("h3", [
                      _vm._v(
                        "Refresh your Browser to update the token numbers."
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.refreshTimer
                ? _c("div", { staticClass: "claimer__timer" }, [_vm._m(4)])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _c("TransactionConfirmPopup", {
        ref: "TransactionConfirmPopup",
        attrs: { hashes: _vm.hashes, sum: _vm.sum },
      }),
      _c("ClaimPopup", { ref: "ClaimPopup" }),
      _c("NetworkPopup", { ref: "networkPopup" }),
      _c("ConnectPopup", { ref: "connectPopup" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "claiming__bg" }, [
      _c("picture", [
        _c("source", {
          attrs: {
            type: "image/avif",
            srcset: require("@/assets/img/claiming/bg.avif"),
          },
        }),
        _c("source", {
          attrs: {
            type: "image/webp",
            srcset: require("@/assets/img/claiming/bg.webp"),
          },
        }),
        _c("source", {
          attrs: {
            type: "image/jpg",
            srcset: require("@/assets/img/claiming/bg.jpg"),
          },
        }),
        _c("img", {
          staticClass: "claiming__bg-img",
          attrs: { srcset: require("@/assets/img/claiming/bg.jpg") },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", [
      _vm._v("Connect your wallet"),
      _c("br"),
      _vm._v(" to start claiming "),
      _c("br"),
      _vm._v(" your tokens."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "claimer__content__title" }, [
      _c("h3", [_vm._v("NEXT $MF TO CLAIM")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "claimer__content__description" }, [
      _c("h5", [
        _vm._v(
          "Please make sure you have a minimum balance of 0.01 BNB to withdraw"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", [
      _vm._v("Please wait some time and refresh the page."),
      _c("br"),
      _vm._v("Claimed tokens are already coming your way."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }