// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".field {\n  display: flex;\n  min-width: 300px;\n  min-height: 44px;\n  max-height: 70px;\n  border: 2px solid #000;\n  margin: 20px 10px;\n}\n.field__left {\n  width: 50%;\n  padding: 10px;\n  background: #fff;\n}\n.field__left__title {\n  text-transform: uppercase;\n  font-size: 18px;\n  font-weight: 500;\n}\n.field__right {\n  width: 51%;\n  padding: 10px;\n  background: #000;\n  text-align: center;\n}\n.field__right__count {\n  color: #fff;\n  text-transform: uppercase;\n  font-weight: 700;\n  font-size: 18px;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
