var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    { staticClass: "stake-btn", on: { click: _vm.clicked } },
    [
      _c("div", { staticClass: "stake-btn__content" }, [
        _vm._v(" Stake $mf - up to 100% apr "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }