// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".claiming-date {\n  display: flex;\n  min-width: 300px;\n  min-height: 60px;\n  border: 1px solid #000;\n  margin: 20px 10px 10px;\n  justify-content: center;\n  align-items: center;\n}\n.claiming-date__date {\n  width: 45%;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n}\n.claiming-date__date__title {\n  text-transform: uppercase;\n  font-size: 18px;\n  font-weight: 500;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
