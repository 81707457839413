var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup",
    {
      on: { input: _vm.close },
      model: {
        value: _vm.popup,
        callback: function ($$v) {
          _vm.popup = $$v
        },
        expression: "popup",
      },
    },
    [
      _c("div", { staticClass: "transaction-confirm-popup" }, [
        _c("h3", { staticClass: "transaction-confirm-popup__title" }, [
          _vm._v(" Wrong network detected. "),
        ]),
        _c("div", { staticClass: "transaction-confirm-popup__text" }, [
          _vm._v(" To continue, please switch network in your wallet to "),
          _c("span", { staticStyle: { "font-weight": "600" } }, [
            _vm._v("Binance Smart Chain."),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }