<template>
    <button class="stake-btn" @click="clicked">
        <div class="stake-btn__content">
            Stake $mf - up to 100% apr
        </div>
    </button>
</template>
<script>
export default {
    name: 'StakeBtn',
    methods: {
        clicked() {
            this.$emit('click')
        }
    }
}
</script>
<style lang="scss">
.stake-btn{
    padding: 2px;
    background: #000;
    width: 100%;
    font-family: "Tektur";
    &__content {
        border: 1px solid #fff;
        color:#fff;
        padding: 10px 20px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-size: 20px;
    }
}
</style>