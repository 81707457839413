// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".date {\n  margin: 0px 10px;\n}\n.date__content {\n  border: 1px solid white;\n  width: -moz-fit-content;\n  width: fit-content;\n  padding: 5px 15px;\n  position: relative;\n}\n.date__content:before {\n  content: \"\";\n  position: absolute;\n  top: 4px;\n  left: 43%;\n  margin-top: -5px;\n  border: 5px solid white;\n  border-right-color: transparent;\n  border-left-color: transparent;\n  border-bottom-color: transparent;\n}\n.date__content__title {\n  font-size: 32px;\n  font-weight: 550;\n}\n.date__subtitle {\n  font-size: 16px;\n  font-weight: 500;\n  display: flex;\n  justify-content: center;\n  text-transform: uppercase;\n}\n.count-down {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
