<template>
    <div class="clamable">
        <div class="clamable__title">
            <span>{{ title }}</span>
        </div>
        <div class="clamable__count">
            <span>{{count}} MF</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Clamable',
    props: {
        title: {
            type: String,
            required: true
        },
        count: {
            type: String,
            required: true
        }
    }
}
</script>
<style lang="scss">
.clamable {
    display: block;
    min-width: 300px;
    margin: 20px 10px 0px 10px;
    justify-content: center;
    align-items: center;
    &__title {
        text-align: center;
        & span {
            text-transform: uppercase;
            font-size: 24px;
            font-weight: bold;
        }
    }
    &__count {
        text-align: center;
        & span {
            text-transform: uppercase;
            font-size: 36px;
            font-weight: bold;
        }
    }
    
}
</style>