var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "clamable" }, [
    _c("div", { staticClass: "clamable__title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c("div", { staticClass: "clamable__count" }, [
      _c("span", [_vm._v(_vm._s(_vm.count) + " MF")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }