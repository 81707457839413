var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vue-countdown", {
        attrs: { time: _vm.getDates[0] },
        on: {
          end: function ($event) {
            return _vm.getNext(_vm.index)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ days, hours, minutes, seconds }) {
              return [
                _c("div", { staticClass: "count-down" }, [
                  _c("div", { staticClass: "date" }, [
                    _c("div", { staticClass: "date__content" }, [
                      _c("h5", { staticClass: "date__content__title" }, [
                        _vm._v(_vm._s(days)),
                      ]),
                    ]),
                    _c("span", { staticClass: "date__subtitle" }, [
                      _vm._v("days"),
                    ]),
                  ]),
                  _c("div", { staticClass: "date" }, [
                    _c("div", { staticClass: "date__content" }, [
                      _c("h5", { staticClass: "date__content__title" }, [
                        _vm._v(_vm._s(hours)),
                      ]),
                    ]),
                    _c("span", { staticClass: "date__subtitle" }, [
                      _vm._v("hours"),
                    ]),
                  ]),
                  _c("div", { staticClass: "date" }, [
                    _c("div", { staticClass: "date__content" }, [
                      _c("h5", { staticClass: "date__content__title" }, [
                        _vm._v(_vm._s(minutes)),
                      ]),
                    ]),
                    _c("span", { staticClass: "date__subtitle" }, [
                      _vm._v("mins"),
                    ]),
                  ]),
                  _c("div", { staticClass: "date" }, [
                    _c("div", { staticClass: "date__content" }, [
                      _c("h5", { staticClass: "date__content__title" }, [
                        _vm._v(_vm._s(seconds)),
                      ]),
                    ]),
                    _c("span", { staticClass: "date__subtitle" }, [
                      _vm._v("secs"),
                    ]),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }