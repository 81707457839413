<template>
  <popup v-model="popup" @input="close">
    <div class="transaction-confirm-popup">
      <h3 class="transaction-confirm-popup__title">
        You have successfully claim.
      </h3>
      <div class="transaction-confirm-popup__text">
        You have claimed only a part of the available MF tokens. To receive the<br> remaining tokens, click the “Claim your MF” button again and confirm the transaction<br> in Metamask. This is due to how you feel in several investment rounds.
        
        </div>
        <span class="transaction-confirm-popup__track">Track your transaction by hash:</span>
        <ul class="track">
          <li class="track__li transfered" v-for="(hash, i) in hashes" :key="i"><span class="success" @click="copyLink(hash.hash)">#{{ hash.hash }}  <img loading="lazy" src="@/assets/img/claiming/copy.svg" alt="" style="cursor: pointer;width: 25px;"></span></li>
        </ul>
        <span class="transaction-confirm-popup__sum">You can close this window and leave the claiming page. In the near future, <br> <span class="transaction-confirm-popup__sum__text">{{getSum(sum)}} MF</span> tokens will be credited to your account.</span>
    </div>
  </popup>
</template>

<script>
import { toDefaultFormat } from '@/utils/formats'
export default {
  name: "TransactionConfirmPopup",
  props: {
    hashes: {
      type: Array,
      default: () => []
    },
    sum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      toDefaultFormat,
      popup: false,
    }
  },
  methods: {
    open(){
      this.popup = true
    },
    close() {
      this.$emit('close')
      this.popup = false
    },
    getCount(data) {
        let a = 0
        if(data) {
          a = +Number(data).toFixed(2)
        } 
        return this.toDefaultFormat(a)
    },
    getSum(data) {
      let a = 0
        if(data) {
          a = +Number(data).toFixed(2)
        } 
        return this.toDefaultFormat(a)
    },
    copyLink(hash) {
      this.copyText(hash)
      window.open(`https://bscscan.com/tx/${hash}`, '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
.transaction-confirm-popup {
  word-break: break-word;
  text-transform: uppercase;
  &__title {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

  &__text {
    margin-top: 20px;
    text-align: center;
  }
  &__track {
    margin: 10px 0;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }
  &__sum {
    margin-top: 20px;
    text-align: center;
    text-align: center;
    display: block;
    &__text {
      font-weight: 600;
    }
  }
}
.declined { 
  color: red;
}
.success {
  color: rgb(26, 82, 238);
  text-decoration: underline;
  cursor: pointer;
}
.track {
  
  &__li {
    list-style: decimal;
    margin: 5px 20px;
    &.err {
      color: red;
    }
    &.transfered {
      color: rgb(26, 82, 238);
    }
  }
}
</style>
