var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "field" }, [
    _c("div", { staticClass: "field__left" }, [
      _c("span", { staticClass: "field__left__title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
    ]),
    _c("div", { staticClass: "field__right" }, [
      _c("span", { staticClass: "field__right__count" }, [
        _vm._v(" " + _vm._s(_vm.count) + " MF"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }