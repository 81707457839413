// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".stake-btn {\n  padding: 2px;\n  background: #000;\n  width: 100%;\n  font-family: \"Tektur\";\n}\n.stake-btn__content {\n  border: 1px solid #fff;\n  color: #fff;\n  padding: 10px 20px;\n  font-weight: 700;\n  text-transform: uppercase;\n  text-align: center;\n  font-size: 20px;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
