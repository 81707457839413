var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "claiming-date" }, [
    _c("div", { staticClass: "claiming-date__date" }, [
      _c("span", { staticClass: "claiming-date__date__title" }, [
        _vm._v(" start date:"),
      ]),
      _c("span", { staticClass: "claiming-date__date__title" }, [
        _vm._v(_vm._s(_vm.startDate)),
      ]),
    ]),
    _c("img", {
      attrs: { src: require("../../assets/img/claiming/middle.svg"), alt: "" },
    }),
    _c("div", { staticClass: "claiming-date__date" }, [
      _c("span", { staticClass: "claiming-date__date__title" }, [
        _vm._v(" Finish date:"),
      ]),
      _c("span", { staticClass: "claiming-date__date__title" }, [
        _vm._v(_vm._s(_vm.endDate)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }