<template>
    <div class="field">
        <div class="field__left">
            <span class="field__left__title">{{title}}</span>
        </div>
        <div class="field__right">
            <span class="field__right__count">
             {{count}} MF</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Field',
    props: {
        title: {
            type: String,
            required: true
        },
        count: {
            type: String,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {},
    methods: {}
}
</script>
<style lang="scss">
.field {
    display: flex;
    min-width: 300px;
    min-height: 44px;
    max-height: 70px;
    border: 2px solid #000;
    margin: 20px 10px;
    &__left {
        width: 50%;
        padding: 10px;
        background: #fff;
        &__title {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 500;
        }
    }
    &__right {
        width: 51%;
        padding: 10px;
        background: #000;
        text-align: center;
        &__count {
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 18px;
        }
    }
}
</style>