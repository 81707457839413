<template>
    <div class="claiming-date">
        <div class="claiming-date__date">
            <span class="claiming-date__date__title"> start date:</span>
            <span class="claiming-date__date__title">{{startDate}}</span>
        </div>
        <img :src="require('../../assets/img/claiming/middle.svg')" alt="">
        <div class="claiming-date__date">
            <span class="claiming-date__date__title"> Finish date:</span>
            <span class="claiming-date__date__title">{{ endDate}}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ClaimingDate',
    props: {
        startDate: {
            type: String,
            required: true
        },
        endDate: {
            type: String,
            required: true
        }
    }
}
</script>
<style lang="scss">
.claiming-date {
    display: flex;
    min-width: 300px;
    min-height: 60px;
    border: 1px solid #000;
    margin: 20px 10px 10px;
    justify-content: center;
    align-items: center;
    &__date {
        width: 45%;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        &__title {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 500;
        }
    }
    
}
</style>