<template>
    <div class="claiming">
        <Preloader v-if="isLoading"/>
        <div  v-if="!isLoading" class="claiming__content">
        
            <div class="claiming__bg">
                <picture>
                    <source type="image/avif" srcset="@/assets/img/claiming/bg.avif">
                    <source type="image/webp" srcset="@/assets/img/claiming/bg.webp">
                    <source type="image/jpg" srcset="@/assets/img/claiming/bg.jpg">
                    <img  class="claiming__bg-img" srcset="@/assets/img/claiming/bg.jpg">
                </picture>
            </div>

            <div class="claimer">
                <div v-if="!account" class="claimer__loggedin">
                    <h3>Connect your wallet<br> to start claiming <br> your tokens.</h3>
                </div>
                <div v-if="account"  class="claimer__staking">
                    <StakeBtn class="claimer__staking__btn" @click="goStacking"/>
                </div>
                <div v-if="account" class="claimer__content">
                    <div class="claimer__content__title">
                        <h3>NEXT $MF TO CLAIM</h3>
                    </div>
                    
                    <div class="claimer__content__date">
                        <DateTimer :claimingPeriods="claimingPeriods" @newreq="getResponse"/>
                        <!--<Date :title="'2022'" :subtitle="'YEAR'"/>-->
                    </div>
                    <div class="claimer__content__description">
                        <h5>Please make sure you have a minimum balance of 0.01 BNB  to withdraw</h5>
                    </div>
                    <div class="claimer__content__counter">
                        <Field :title="'all your mf'" :count="getAllMF"/>
                        <Field :title="'mf claimed'" :count="getMFclaimed"/>
                        <Field :title="'mf remains'" :count="getMFAvailable"/>
                    </div>
                    <div class="claimer__content__expire">
                        <ClaimingDate :startDate="startDate" :endDate="endDate"/>
                    </div>
                    <div class="claimer__content__clamable">
                        <Clamable :title="'claimable now'" :count="getMFreleasablenow"/>
                    </div>
                    <div class="claimer__content__button">
                        <ClaimBtn title="claim your mf" type="play" @click="claimableNow" class="claimer__content__button__btn"/>
                    </div>
                </div>
                <div v-if="refreshPage" class="claimer__claimed">
                    <h3>Refresh your Browser to update the token numbers.</h3>
                </div>
                <div v-if="refreshTimer" class="claimer__timer">
                    <h3>Please wait some time and refresh the page.<br>Claimed tokens are already coming your way.</h3>
                </div>
            </div>
        </div>
        <TransactionConfirmPopup ref="TransactionConfirmPopup" :hashes="hashes" :sum="sum"/>
        <ClaimPopup ref="ClaimPopup"/>
        <NetworkPopup ref="networkPopup"/>
        <ConnectPopup ref="connectPopup"/>
    </div>
</template>
<script>
import { toDefaultFormat } from '@/utils/formats'
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'
import Field from '@/components/claiming/Field.vue'
import ClaimingDate from '@/components/claiming/ClaimingDate.vue'
import Clamable from '@/components/claiming/Clamable.vue'
import TransactionConfirmPopup from '@/components/popups/transaction-confirm-popup.vue'
import NetworkPopup from '@/components/popups/network-popup.vue'
import DateTimer from '@/components/claiming/DateTimer.vue'
import ClaimPopup from '@/components/popups/claim-popup.vue'
import ConnectPopup from '@/components/popups/connect-popup.vue'
import ClaimBtn from '@/components/claiming/Btn.vue'
import StakeBtn from '@/components/claiming/StakeBtn.vue'
export default {
    name: 'Claiming',
    components: {
        Field,
        ClaimingDate,
        Clamable,
        TransactionConfirmPopup,
        DateTimer,
        ClaimPopup,
        ClaimBtn,
        NetworkPopup,
        ConnectPopup,
        StakeBtn
    },
    data() {
        return {
            toDefaultFormat,
            vestingSchedule: [],
            allMF: 0,
            mfclaimed: 0,
            mfremains: 0,
            claimablenow: 0,
            cliff: 0,
            startDate : '',
            endDate:  '',
            transactionData: {},
            hashes: [],
            transaction: false,
            allStartDate : [],
            allEndDate:  [],
            allCliff: [],
            divider: 1000000000000000000,
            releaseSum : 0,
            allSlicePeriod: [],
            claimingPeriods: [],
            claimingAfter: '',
            investorBalance: null,
            isLoading: false,
            sum: 0,
            notEnought: false,
            refreshPage: false,
            refreshTimer: false,

        }
    },
    computed: {
        ...mapGetters(['account', 'contract', 'loading', 'walletconnect', 'metamaskconnect']),
        getMFAvailable() {
            return this.toDefaultFormat(Math.abs(this.mfremains).toFixed(2))
        },
        getAllMF() {
            return this.toDefaultFormat(Math.abs(this.allMF).toFixed(2))
        },
        getMFclaimed() {
            return this.toDefaultFormat(Math.abs(this.mfclaimed).toFixed(2))
        },
        getMFreleasablenow() {
            return this.toDefaultFormat(Math.abs(this.claimablenow).toFixed(2))
        },
    },
    watch: {
        loading(val) {
            if(val === true) {
                this.getWholeData()
            }
        },
        claimablenow(n, o) {
            if(n === o) {
                this.refreshPage === true ? this.refreshPage = false : this.refreshTimer = false
            }
        },
        account(n, o) {
            if(n !== o) {
                this.getWholeData()
            }
        }
    },
    created(){
        this.getWholeData()
    },
    methods: {
        ...mapActions(['getSmartContract', 'getMFToken', 'checkIfConnected', 'getBalance', 'getInvestorRoundsArray', 'getBalance', 'networkId', 'loadPage', 'checkAccountHas']),
        async claimableNow() {
            const connected = await this.checkAccountHas() 
            if(connected) {
                const Vesting = await this.getSmartContract()
                let sum = 0
                if(this.metamaskconnect) {
                    const VScount = await Vesting.getVestingSchedulesCountByInvestor(this.account);
                    this.notEnought = false
                    let index;
                    for(index = 0; index < parseInt(VScount); index++) {
                        const vestingScheduleId = await Vesting.computeInvestorDataIdForAddressAndIndex(this.account, index)
                        const ReleasableAmount = await Vesting.computeReleasableAmount(vestingScheduleId)
                        if(parseFloat(ReleasableAmount / this.divider) > 0) {
                            try {
                                this.transactionData = await Vesting.release(vestingScheduleId, ReleasableAmount.toString()) //error
                                this.hashes.push({ hash: this.transactionData.hash, count: (parseFloat(ReleasableAmount) / this.divider) })
                                sum += (parseFloat(ReleasableAmount) / this.divider)
                            } catch(err) {
                                if(err.code === 4001) {
                                    this.notEnought = false
                                }
                                console.log(err)
                            }
                        }
                        else if(parseFloat(ReleasableAmount / this.divider) === 0) {
                            this.notEnought = true
                        }
                    }
                } else if(this.walletconnect) {
                    const VScount = await Vesting.methods.getVestingSchedulesCountByInvestor(this.account).call();
                    this.notEnought = false
                    let index;
                    for(index = 0; index < parseInt(VScount); index++) {
                        const vestingScheduleId = await Vesting.methods.computeInvestorDataIdForAddressAndIndex(this.account, index).call();
                        const ReleasableAmount = await Vesting.methods.computeReleasableAmount(vestingScheduleId).call();
                        if(parseFloat(ReleasableAmount / this.divider) > 0) {
                            try {
                                this.transactionData = await Vesting.methods.release(vestingScheduleId, ReleasableAmount.toString()).call() //error
                                this.hashes.push({ hash: this.transactionData.hash, count: (parseFloat(ReleasableAmount) / this.divider) })
                                sum += (parseFloat(ReleasableAmount) / this.divider)
                            } catch(err) {
                                if(err.code === 4001) {
                                    this.notEnought = false
                                }
                                console.log(err)
                            }
                        }
                        else if(parseFloat(ReleasableAmount / this.divider) === 0) {
                            this.notEnought = true
                        }
                    }
                }
                
                this.sum = sum
                if(this.hashes.length) {
                    this.$refs.TransactionConfirmPopup.open()
                    this.refreshPage = true
                }
                else if(this.notEnought) {
                    this.$refs.ClaimPopup.open()
                }
            }
        },
        async getVestingArray() {
            this.isLoading = true
            this.allMF =  0;
            this.mfclaimed = 0;
            this.mfremains = 0;
            this.claimablenow = 0;
            const res = await this.checkIfConnected()
            if(res) {
                const Vesting = await this.getSmartContract()
                if(Vesting) {
                    const investorArray = await Vesting.getScheduleArrayByInvestor(this.account)
                    if(investorArray.length) {
                        let arr = []
                        let now = moment().format("DD.MM.YYYY HH:mm:ss")
                        investorArray.forEach((item) => {
                            this.allMF += ((parseFloat(item.amount) / this.divider) + (parseFloat(item.amountAfterCliff) / this.divider))
                            this.mfclaimed += parseFloat(item.releasedTotal)  / this.divider
                            this.mfremains += (((parseFloat(item.amount) / this.divider) + (parseFloat(item.amountAfterCliff) / this.divider)) - (parseFloat(item.releasedTotal)  / this.divider))
                            this.claimablenow += (parseFloat(item.releasableAmount) / this.divider)
                            
                            //Start and End dates
                            let startDate = moment(item.start.toNumber() * 1000).format('DD.MM.YYYY HH:mm')
                            this.allStartDate.push(startDate)
                            let endDate = moment((item.cliff.toNumber() + item.duration.toNumber())* 1000).format('DD.MM.YYYY HH:mm')
                            this.allEndDate.push(endDate)
                            this.allStartDate.sort((d1, d2) => {
                                return moment(d1, 'DD.MM.YYYY HH:mm').isBefore(moment(d2, 'DD.MM.YYYY HH:mm'));
                            });
                            this.allEndDate.sort((d1, d2) => {
                                return moment(d1, 'DD.MM.YYYY HH:mm').isBefore(moment(d2, 'DD.MM.YYYY HH:mm'));
                            });                
                            //Start and End dates
                            //Next Clime
                            let slicePeriodSeconds = item.slicePeriodSeconds.toNumber()
                            let claimingtime = slicePeriodSeconds / 60
                            let durationMinutes = item.duration.toNumber() / 60
                            let clif = item.cliff.toNumber()
                            let a = durationMinutes / claimingtime
                            arr.push(moment(clif * 1000).format('DD.MM.YYYY HH:mm:ss'))
                            for(let i = 1; i <= a; i++) {
                                arr.push(moment(clif * 1000).add(claimingtime*i, 'minutes').format('DD.MM.YYYY HH:mm:ss'))
                            }
                            arr = arr.sort((a, b) => a.localeCompare(b))
                            this.claimingPeriods = [...arr.filter(i => i >= now)]
                            this.claimingAfter = this.claimingPeriods[0]
                            //Next Clime
                        })
                        this.claimingPeriods = this.claimingPeriods.filter(i => i > now).map(i => moment(i, 'DD.MM.YYYY HH:mm:ss').valueOf() - moment().valueOf())
                        this.allStartDate.sort((a, b) => a.localeCompare(b))
                        this.allEndDate.sort((a, b) => a.localeCompare(b))
                        console.warn(this.allEndDate)
                        if(this.allStartDate.length) {
                            this.startDate = this.allStartDate[0]
                            this.endDate = this.allEndDate[this.allEndDate.length-1]
                        }
                        this.getBalanceOfInvestor()
                    } 
                    else {
                        this.isLoading = false
                        this.startDate = null
                        this.endDate = null
                    }
                    
                    
                }
                else {
                    this.isLoading = false
                }
            }
            else {
                this.isLoading = false
            }
            
        },
        async getWholeData() {
            this.isLoading = true
            this.hashes = []
            this.allMF =  0;
            this.mfclaimed = 0;
            this.mfremains = 0;
            this.claimablenow = 0;
            const isConnected = await  this.checkAccountHas() //this.checkIfConnected()
            if(isConnected) {
                const networkId = await this.networkId()
                if(networkId) {
                    const investorArray = await this.getInvestorRoundsArray()
                    let arr = []
                    //let now = moment().format("DD.MM.YYYY HH:mm:ss")
                    if(!investorArray.err) {
                        await this.getBalance()
                        investorArray.forEach((item) => {
                            //ALL MF
                            this.allMF += ((parseFloat(item.amount) / this.divider) + (parseFloat(item.amountAfterCliff) / this.divider))
                            //MF CLAIMED
                            this.mfclaimed += parseFloat(item.releasedTotal)  / this.divider
                            //MF REMAINS
                            this.mfremains += (((parseFloat(item.amount) / this.divider) + (parseFloat(item.amountAfterCliff) / this.divider)) - (parseFloat(item.releasedTotal)  / this.divider))
                            //CLAIMABLE NOW
                            //
                            /*let amountAfterCliff = 0
                            if(!moment((item.cliff.toNumber() + item.duration.toNumber())* 1000, 'DD.MM.YYYY HH:mm:ss').isAfter()){ // не после текущей даты
                                if(!item.cliffPaid) {
                                    amountAfterCliff = (parseFloat(item.amountAfterCliff) / this.divider)
                                }
                            }*/
                            // Если переменная amountAfterCliff более нуля то нам нужно разбить клайм транзакцию на две суммы. На сумму releasableAmount и amountAfterCliff.
                            // amountAfterCliff нужно выплачивать в разрезе фаз. Нужно подумать как ее передовать в функцию оплаты.
                            this.claimablenow += ((parseFloat(item.releasableAmount) / this.divider)) //+ amountAfterCliff
                            //CLAIMABLE NOW
                            //Start and End dates
                            let startDate = moment(parseInt(item.start) * 1000).format('DD.MM.YYYY HH:mm') // * 1000
                            //console.log(`start date --- ${startDate}`)
                            //console.log(`PhaseId --- ${item.phaseID.toNumber()}`)
                            this.allStartDate.push(startDate)
                            let endDate = moment((parseInt(item.cliff) + parseInt(item.duration))* 1000).format('DD.MM.YYYY HH:mm') //* 1000
                            this.allEndDate.push(endDate)
                                /*this.allStartDate = this.allStartDate.filter(i => { if(moment(`${i}`, "DD.MM.YYYY HH:mm:ss").isAfter()) { return i } })
                                this.allEndDate = this.allEndDate.filter(i => { if(moment(`${i}`, "DD.MM.YYYY HH:mm:ss").isAfter()) { return i } })*/
                            //Start and End dates
                            //Next Clime
                            let slicePeriodSeconds = parseInt(item.slicePeriodSeconds)
                            let claimingtime = slicePeriodSeconds / 60
                            let durationMinutes = parseInt(item.duration) / 60
                            let clif = parseInt(item.cliff)
                            let a = durationMinutes / claimingtime
                            if(parseInt(item.cliffPercent) !== 0) {
                                arr.push(moment(clif * 1000).format('DD.MM.YYYY HH:mm:ss')) //* 1000
                            }
                            for(let i = 1; i <= a; i++) {
                                arr.push(moment(clif * 1000).add(claimingtime*i, 'minutes').format('DD.MM.YYYY HH:mm:ss')) //* 1000
                            }
                            //arr = arr.sort((a, b) => a.localeCompare(b))
                            this.claimingPeriods = [...arr.filter(i => {
                                if(moment(`${i}`, "DD.MM.YYYY HH:mm:ss").isAfter()) {
                                    return i
                                }
                            })]
                            //Next Clime 
                        })
                    }
                    this.claimingPeriods = this.claimingPeriods.map(i => moment(i, 'DD.MM.YYYY HH:mm:ss').valueOf() - moment().valueOf()).sort((a,b) => { return a-b })
                    //Start Date
                        //this.allStartDate.sort((a, b) => a.localeCompare(b))
                        //this.allEndDate.sort((a, b) => a.localeCompare(b))
                    this.allStartDate = this.allStartDate.sort((a, b) => { return moment(a, "DD.MM.YYYY HH:mm").diff(moment(b, "DD.MM.YYYY HH:mm")) })
                    this.allEndDate = this.allEndDate.sort((a, b) => { return moment(a, "DD.MM.YYYY HH:mm").diff(moment(b, "DD.MM.YYYY HH:mm")) })
                    //Finish Date
                    if(this.allStartDate.length) {
                        this.startDate = this.allStartDate[0]
                        this.endDate = this.allEndDate[this.allEndDate.length-1]
                    }
                    await this.loadPage(false)
                    setTimeout(() => { this.isLoading = false }, 300)
                    if(!investorArray.length) {
                        this.startDate = ''
                        this.endDate = ''
                    }
                } else {
                    this.isLoading = false
                    //this.refreshTimer = false
                    //this.refreshPage = false
                    await this.loadPage(false)
                    this.$refs.networkPopup.open()
                }
            }
            else {
                this.isLoading = false
                //this.refreshTimer = false
                //this.refreshPage = false
                await this.loadPage(false)
                //this.$refs.connectPopup.open()
            }
        },
        goStacking() {
            window.open('https://stake.metafighter.com', '_blank')
        },
        getResponse() {
            this.refreshTimer = true;
        }
    }
}
</script>
<style lang="scss">
.claiming {
    display: flex;
    height: 100%;
    max-height: 100vh;
    
    .claiming__bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -2;

        &-img {
            position: absolute;
            z-index: -2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &:before {
            z-index: -1;
            background: linear-gradient(to right, #FFC812 20%, #FF9509);
            mix-blend-mode: multiply;
        }

        &:after {
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            max-width: 350px;
            margin: auto;
            z-index: 0;
            
            background-color: rgba(0,0,0,.05);
        }
    }

    &__content {
        padding: 0px 20px;
        width: 100%;
        min-height: 100%;
        z-index: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-direction: column;
        @media screen and (max-width: $bp_mb) {
            padding-top: 100px;
        }
    }
}
.claimer {
    min-width: 350px;
    max-width: 550px;
    width: 100%;
    padding: 60px 0px;
    &__content {
        border: 2px solid rgb(231, 231, 231);
        padding: 20px 20px 40px 20px;
        position: relative;
        &__title {
            & h3 {
                color: #000;
                text-transform: uppercase;
                font-size: 28px;
                font-weight: 600;
                //font-family: 'Oswald', sans-serif;
                text-align: center;
                position: absolute;
                top: -10px;
                left: 30%;
                transform: translate(-10%, -8px);
                background: linear-gradient(to right, #fdc241 60%, #fcc141);
                box-shadow: 5px 1px 25px 10px #FFC812;
            }
        }
        &__description {
            width: 75%;
            padding: 10px 0 10px 0;
            margin: auto;
            & h5 {
                font-family: 'Tektur';
                font-size: 16px;
                text-align: center;
            }
            
        }
        &__date {
            display: flex;
            justify-content: center;
            margin-top: 10px;
        }
        &__button {
            position: absolute;
            bottom: -30px;
            left: 15%;
            transform: translate(30px, -15%) !important;
        }
        &__counter {
            margin-top: 20px;
        }
        &__expire {}
    }
    &__staking {
        display: flex;  
        justify-content: center;
        margin-bottom: 30px;
    }
    &__loggedin{ 
        & h3 {
            color: #000;
            text-transform: uppercase;
            font-size: 22px;
            font-weight: 600;
            //font-family: 'Oswald', sans-serif;
            text-align: center;
        }
    }
    &__claimed{
        margin-top: 60px;
        & h3 {
            color: #000;
            //text-transform: uppercase;
            font-size: 18px;
            font-weight: 600;
            //font-family: 'Oswald', sans-serif;
            text-align: center;
        }
    }
    &__timer{
        margin-top: 60px;
        & h3 {
            color: #000;
            //text-transform: uppercase;
            font-size: 18px;
            font-weight: 600;
            //font-family: 'Oswald', sans-serif;
            text-align: center;
        }
    }

}
@media screen and (max-width: 600px) and (min-width: 300px) {
    .claimer {
        &__content {
            &__title {
                & h3 {
                    font-size: 24px;
                }
            }
            &__button {
                position: absolute;
                bottom: -30px;
                left: -5%;
                transform: translate(30px, -5%) !important;
            }
        }
    }
}
</style>