<template>
  <button
    type="button"
    class="claimer-btn"
    @click="click"
  >
    <span class="first-line"></span>
    <span class="second-line"></span>
    <span class="third-line"></span>
    <span class="fourth-line"></span>
    <span class="claimer-btn__content">
      <span class="claimer-btn__content__title">{{ title }}</span>
    </span>
  </button>
</template>

<script>
export default {
  name: "Btn",
  props: {
    title: { type: String, default: '' },
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">

.claimer-btn {
    font: 500 15px "Tektur";
    padding: 10px;
    min-width: 320px;
    min-height: 44px;
    max-height: 70px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translate(-0%, -100%);
    box-shadow: 0px 10px 10px #000000ab;
    overflow:hidden;
    background: linear-gradient(to right, #FFC812 20%, #FF9509);
    &__content {
        background: #000;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__title {
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
}
.claimer-btn::before{
  content: '';
  top: 2px;
  left: 2px;
  bottom: 2px;
  position: absolute;
  opacity: .5;
}
button .first-line
{
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #ffffff, #ffffff, #ffffff);
  position:absolute;
  animation: animate1 2s linear infinite paused;
}

@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }
  
  100% {
        transform: translateX(100%);

  }
}
button .second-line 
{
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(to bottom, #ffffff, #ffffff, #ffffff);
  position:absolute;
  animation: animate2 2s linear infinite paused;
}

@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }
  
  100% {
        transform: translateY(100%);

  }
}
button .third-line
{
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, #ffffff, #ffffff, #ffffff);
  position:absolute;
animation: animate3 2s linear infinite paused;
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }
  
  100% {
        transform: translateX(-100%);

  }
}
button .fourth-line 
{
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(to top, #ffffff, #ffffff, #ffffff);
  position:absolute;
  animation: animate4 2s linear infinite paused;
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }
  
  100% {
        transform: translateY(-100%);

  }
}

.claimer-btn:hover .first-line,
.claimer-btn:hover .second-line,
.claimer-btn:hover .third-line,
.claimer-btn:hover .fourth-line {
  animation-play-state: running;
}
</style>
