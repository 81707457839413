var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup",
    {
      on: { input: _vm.close },
      model: {
        value: _vm.popup,
        callback: function ($$v) {
          _vm.popup = $$v
        },
        expression: "popup",
      },
    },
    [
      _c("div", { staticClass: "transaction-confirm-popup" }, [
        _c("h3", { staticClass: "transaction-confirm-popup__title" }, [
          _vm._v(" You have successfully claim. "),
        ]),
        _c("div", { staticClass: "transaction-confirm-popup__text" }, [
          _vm._v(
            " You have claimed only a part of the available MF tokens. To receive the"
          ),
          _c("br"),
          _vm._v(
            " remaining tokens, click the “Claim your MF” button again and confirm the transaction"
          ),
          _c("br"),
          _vm._v(
            " in Metamask. This is due to how you feel in several investment rounds. "
          ),
        ]),
        _c("span", { staticClass: "transaction-confirm-popup__track" }, [
          _vm._v("Track your transaction by hash:"),
        ]),
        _c(
          "ul",
          { staticClass: "track" },
          _vm._l(_vm.hashes, function (hash, i) {
            return _c("li", { key: i, staticClass: "track__li transfered" }, [
              _c(
                "span",
                {
                  staticClass: "success",
                  on: {
                    click: function ($event) {
                      return _vm.copyLink(hash.hash)
                    },
                  },
                },
                [
                  _vm._v("#" + _vm._s(hash.hash) + " "),
                  _c("img", {
                    staticStyle: { cursor: "pointer", width: "25px" },
                    attrs: {
                      loading: "lazy",
                      src: require("@/assets/img/claiming/copy.svg"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ])
          }),
          0
        ),
        _c("span", { staticClass: "transaction-confirm-popup__sum" }, [
          _vm._v(
            "You can close this window and leave the claiming page. In the near future, "
          ),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "transaction-confirm-popup__sum__text" }, [
            _vm._v(_vm._s(_vm.getSum(_vm.sum)) + " MF"),
          ]),
          _vm._v(" tokens will be credited to your account."),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }